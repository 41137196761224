import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line no-unused-vars
import { collection, query, limit, where, doc, getDoc, getDocs, onSnapshot, orderBy, startAt, endAt } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {sendSignInLinkToEmail, signOut} from "firebase/auth";
// eslint-disable-next-line no-unused-vars
import { db, auth, cloud } from "@/firebase.js"
// eslint-disable-next-line no-unused-vars
import Plugins from '@/plugins/plugins'

Vue.use(Vuex)
Vue.use(Plugins)


export default new Vuex.Store({
  state: {
    blockchains: [],
    tags: [],

    myDonuts: [],

    donuts: [],

    donut: {},
    donutEvents: [],
    donutEventsOld: [],
    donutLinks: [],

    calendarEvents: [],
    calendarEventDates: [],

    user: {
      id: false,
      data: []
    },

    showLoginModal: false,


  },
  mutations: {
    // eslint-disable-next-line no-unused-vars
    LOGOUT(state, user) {
      Vue.set(state, 'user', {id:null, data: [], meta: []});
    },
    SET_LOGIN_MODAL(state, isShown){
      state.showLoginModal = isShown;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_META(state, data) {
      Vue.set(state.user, 'meta', data);
    },
    ADD_CHAINS(state, list) {
      state.blockchains = list;
    },
    ADD_TAGS(state, list) {
      state.tags = list;
    },
    ADD_DONUTS(state, list) {
      state.donuts = list;
    },
    ADD_MY_DONUTS(state, list) {
      state.myDonuts = list;
    },
    SET_DONUT(state, donut) {
      // Vue.set(state, 'donut', donut);
      state.donut = donut;
    },
    SET_DONUT_EVENTS(state, events) {
      state.donutEvents = events;
    },
    SET_DONUT_EVENTS_OLD(state, events) {
      state.donutEventsOld = events;
    },
    SET_CALENDAR_EVENTS(state, events) {
      state.calendarEvents.push(...events.data);
      if(!state.calendarEventDates.includes(events.time)){
        state.calendarEventDates.push(events.time)
      }
    },
    CLEAR_CALENDAR_EVENTS(state) {
      state.calendarEvents = [];
      state.calendarEventDates = [];
    },
    SET_DONUT_LINKS(state, links) {
      state.donutLinks = links;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async login({ commit }, email) {
      process.env.NODE_ENV === "development" ? console.log( 'email', email ) : null;
        //set email locally
        window.localStorage.setItem('emailForSignIn', email);

        var varUrl = process.env.VUE_APP_SIGNIN_URL;
        var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // url: NGROK ? 'https://46c603d537dc.ngrok.io' : `${varUrl}/emailAuth`,
        url:`${varUrl}/emailAuth`,
        // This must be true.
        handleCodeInApp: true,
        };
        const functions = getFunctions();
        const sendVerificationEmail = httpsCallable(functions, 'sendVerificationEmail');
        await sendVerificationEmail({email: email, actionCodeSettings: actionCodeSettings}).then((result) => {
          return true;
        });
        // sendSignInLinkToEmail(auth, email, actionCodeSettings)
        // .then(() => {
        //   // The link was successfully sent. Inform the user.
        //   // Save the email locally so you don't need to ask the user for it again
        //   // if they open the link on the same device.
        //   window.localStorage.setItem('emailForSignIn', email);
        //   resolve('done!');
        //   // ...
        // })
        // .catch((error) => {
        //   const errorCode = error.code;
        //   const errorMessage = error.message;
        //   reject(errorCode, errorMessage )
        //   // ...
        // });
    },

    // eslint-disable-next-line no-unused-vars
    saveUser({ commit, state }, user) {
      commit('SET_USER', user);
    },
    // eslint-disable-next-line no-unused-vars
    showLoginModal({ commit, state }, isShown) {
      commit('SET_LOGIN_MODAL', isShown);
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        auth.signOut(auth)
          .then(() => {
            commit('LOGOUT');
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // // eslint-disable-next-line no-unused-vars
    // async getCurrentUser({commit, state}, user){
    //   process.env.NODE_ENV === "development" ? console.log( 'store.getCurrentUser', user ) : null;
    //   const docRef = doc(db, "users", user.uid);
    //   const docSnap = await getDoc(docRef);
    //   if (docSnap.exists()) {
    //     const meta = {'id': docSnap.id, ...docSnap.data()};
    //     process.env.NODE_ENV === "development" ? console.log( "Document data:", docSnap.data() ) : null;
    //     commit('SET_USER', meta)
    //     return meta;
    //   } else {
    //     // doc.data() will be undefined in this case
    //     console.log("No such document!");
    //     return "error";
    //   }
    // },
    async getUserMeta ({ commit, state }, uid) {
        // process.env.NODE_ENV === "development" ? console.log( 'store.getUserMeta', uid, $isEmpty(state.user.meta) ) : null;
        // eslint-disable-next-line no-unused-vars
        const docRef =  doc(db, 'users', uid)
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()){
           // process.env.NODE_ENV === "development" ? console.log( 'docSnap.data()', docSnap.data() ) : null;
           commit('SET_USER_META', docSnap.data())
           return docSnap.data();
        }
        else{
          return null;
        }
    },
    fetchBlockchains ({ commit, state }, unsub) {
      const q = query(collection(db, '/blockchains'));
      let colClean = []
      if(state.blockchains.length == 0){
        // eslint-disable-next-line no-unused-vars
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          colClean = [];
          querySnapshot.forEach((doc) => {
            colClean.push(doc.data())
          });
          commit('ADD_CHAINS', colClean)
        });
        //mutation
        if(!$isUndefined(unsub)){unsubscribe()}
      }
    },
    fetchTags ({ commit, state }, unsub) {
      const q = query(collection(db, '/tags'));
      let colClean = []
      if(state.tags.length == 0){
        // eslint-disable-next-line no-unused-vars
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          colClean = [];
          querySnapshot.forEach((doc) => {
            colClean.push({'id': doc.id, ...doc.data()})
          });
          commit('ADD_TAGS', colClean)
        });
        //mutation
        if(!$isUndefined(unsub)){unsubscribe()}
      }
    },
    // eslint-disable-next-line no-unused-vars
    async checkToken({commit, state}, token){
      const tokens = [];
      const donuts = collection(db, "donuts");
      const q = query(donuts, where("token", "==", token));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        tokens.push(doc.data());
        // console.log(doc.id, " => ", doc.data());
      });
      return tokens;
    },
    // eslint-disable-next-line no-unused-vars
    async getDonut({commit, state}, symbol){
      // process.env.NODE_ENV === "development" ? console.log( 'getDonut', symbol ) : null;
      const q = query(collection(db, "donuts"), where('symbol', '==', symbol), limit(1));
      const querySnapshot = await getDocs(q);
      let colClean = [];
      querySnapshot.forEach((doc) => {
        // process.env.NODE_ENV === "development" ? console.log( 'doc.data', doc.data() ) : null;
        if(state.calendarEvents.some(event => event.id === doc.id)){
          return
        }
        else{
          colClean.push({'id': doc.id, ...doc.data()})
        }
      });
      if(colClean.length == 0){
        return 'error';
      }
      else{
        commit('SET_DONUT', colClean[0])
        return 'loaded'
      }
      // if (docSnap.exists()) {
      //   const donut = {'id': docSnap.id, ...docSnap.data()};
      //   process.env.NODE_ENV === "development" ? console.log( "Document data:", docSnap.data() ) : null;
      //   commit('SET_DONUT', donut)
      //   // commit('addDonut', donut)
      //   return 'loaded';
      // } else {
      //   // doc.data() will be undefined in this case
      //   console.log("No such document!");
      //   return "error";
      // }
    },
    // async setDonut ({ commit, state }, donut) {
    //   process.env.NODE_ENV === "development" ? console.log( '~~~~SET_DONUT', donut ) : null;
    //   commit('SET_DONUT', donut)
    //   return
    // },
    fetchDonuts ({ commit, state }, unsub) {
      if(state.donuts.length == 0){
      const q = query(collection(db, '/donuts'));
      let colClean = []
        // eslint-disable-next-line no-unused-vars
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          colClean = [];
          querySnapshot.forEach((doc) => {
            colClean.push({'id': doc.id, ...doc.data()})
          });
          commit('ADD_DONUTS', colClean)
          const found = colClean.find((item) => item.id === state.donut.id);
          if(!$isUndefined(found)){
            commit('SET_DONUT', found);
          }
          return colClean.length
        });
        //mutation
        if(!$isUndefined(unsub)){unsubscribe()}
      }
    },
    async fetchMyDonuts ({ commit, state }, unsub) {
      process.env.NODE_ENV === "development" ? console.log( 'fetchMyDonutsStore', state.user.id ) : null;

      const q = query(collection(db, '/donuts'), where("inBoxes", "array-contains", state.user.id));
      const querySnapshot = await getDocs(q);
      let colClean = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        colClean.push({'id': doc.id, ...doc.data()})
        // console.log(doc.id, " => ", doc.data());
      });
        // eslint-disable-next-line no-unused-vars
        // const unsubscribe = onSnapshot(q, (querySnapshot) => {
        //   colClean = [];
        //   querySnapshot.forEach((doc) => {
        //   process.env.NODE_ENV === "development" ? console.log( 'my done doc', doc.data() ) : null;
        //     colClean.push({'id': doc.id, ...doc.data()})
        //   });
          commit('ADD_MY_DONUTS', colClean)
          return 'loaded'

        //mutation
        // if(!$isUndefined(unsub)){unsubscribe()}

    },
    async fetchDonutEvents ({ commit, state }, {donutId, time, frame}) {
      process.env.NODE_ENV === "development" ? console.log( 'donutID and FRAME', donutId, frame ) : null;
      // eslint-disable-next-line no-unused-vars
      const q = frame == 'future' ? query(collection(db, "events"), where("donutId", "==", donutId), where('end_unix', '>=', time)) : query(collection(db, "events"), where("donutId", "==", donutId), where('end_unix', '<', time));
      let colClean = []
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let newArray = {...doc.data(), id: doc.id};
        colClean.push(newArray);
      });
      if(frame == 'future'){
        commit('SET_DONUT_EVENTS', colClean);
        return 'done';
      }
      else{
        commit('SET_DONUT_EVENTS_OLD', colClean);
        return colClean.length
      }
    },

    async fetchCalendarEvents ({ commit, state }, {start_time, end_time}) {
      // process.env.NODE_ENV === "development" ? console.log( '~~~fetchCalendarEvents', start_time, end_time, typeof end_time ) : null;
      // process.env.NODE_ENV === "development" ? console.log( 'state.calendarEventDates', state.calendarEventDates ) : null;
      // process.env.NODE_ENV === "development" ? console.log( 'state.calendarEventDates.includes(end_time)', state.calendarEventDates.includes(end_time), state.calendarEventDates.indexOf(end_time) ) : null;
      let inArray = await state.calendarEventDates;
      // process.env.NODE_ENV === "development" ? console.log( 'inArray', inArray, inArray.includes(end_time) ) : null;
      if($isEmpty(inArray) || !inArray.includes(end_time)){
        // process.env.NODE_ENV === "development" ? console.log( 'found.....', state.calendarEventDates ) : null;
        const q = query(collection(db, "events"), orderBy("end_unix"), startAt(start_time), endAt(end_time));
        let colClean = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // process.env.NODE_ENV === "development" ? console.log( 'found events', doc.data() ) : null;
          let newArray = {...doc.data(), id: doc.id};
          colClean.push(newArray);
        });

        commit('SET_CALENDAR_EVENTS', {data: colClean, time: end_time});
        return
      }
      else{
        return
      }
    },
    clearCalendar ({ commit, state }) {
      commit('CLEAR_CALENDAR_EVENTS');
      return
    },

    async fetchDonutLinks ({ commit, state }, donutId) {
      // eslint-disable-next-line no-unused-vars
      const q = query(collection(db, "links"), where("donutId", "==", donutId));
      let colClean = []
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let newArray = {...doc.data(), id: doc.id};
        colClean.push(newArray);
      });
      commit('SET_DONUT_LINKS', colClean);
      return 'done';
    },
    // eslint-disable-next-line no-unused-vars
    setDonut({commit, state}, donut){
      process.env.NODE_ENV === "development" ? console.log( '~~~setDONUT', donut ) : null;
      commit('SET_DONUT', donut);
    },
    // eslint-disable-next-line no-unused-vars
    clearDonut({commit, state}){
      commit('SET_DONUT_EVENTS', []);
      commit('SET_DONUT_EVENTS_OLD', []);
      commit('SET_DONUT_LINKS', []);
    },

  },
  getters: {
    hasUserMeta:(state) => {
      return !$isEmpty(state.user.meta);
    },
    getLoginModal:(state) => {
      return state.showLoginModal;
    },
    getCurrentUser:(state) => {
      return state.user;
    },
    getBlockchains:(state) => {
      return state.blockchains;
    },
    getTags:(state) => {
      function compare(a, b) {
        if (a.text < b.text)
          return -1;
        if (a.text > b.text)
          return 1;
        return 0;
      }
      return [{ header: 'Select an option or create one' },...state.tags.slice().sort(compare)]
    },
    getDonut:(state) => {
      return state.donut;
    },
    getDonutId:(state) => {
      return state.donut.id;
    },
    getDonuts:(state) => {
      return state.donuts;
    },
    getMyDonuts:(state) => {
      return state.myDonuts;
    },
    getDonutEvents:(state) => {
      return state.donutEvents;
    },
    getDonutEventsOld:(state) => {
      return state.donutEventsOld;
    },
    getCalendarEvents:(state) => {
      return state.calendarEvents;
    },
    getDonutLinks:(state) => {
      return state.donutLinks;
    },
  },
  modules: {
  }
});

const $isUndefined = function(thing) {
  if (thing == "undefined" || thing == null){
    return true;
  }
  else{
    return false;
  }
};

// this is to help firebase data object convert to a usable array
const $isEmpty = function (obj) {
  for(var key in obj) {
    if(Object.prototype.hasOwnProperty.call(obj, key))
    return false;
  }
  return true;
};

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';


dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

// Object.defineProperties(Vue.prototype, {
//   $date: {
//     get() {
//       return dayjs
//     }
//   }
// });

export default {
  install(Vue) {
    Vue.prototype.$date = dayjs
  },
};

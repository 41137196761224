<template>
  <v-app :class="{'desktop': $vuetify.breakpoint.mdAndUp, 'tablet': $vuetify.breakpoint.smOnly, 'hide-nav' : hideNavigation}">
    <navigation v-if="!hideNavigation"></navigation>
    <v-main>
      <router-view/>
    </v-main>
    <login-modal v-if="getLoginModal"></login-modal>
  </v-app>
</template>

<script>
import Navigation from '@/components/nav'
const LoginModal = () => import("@/components/loginModal")
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components:{Navigation, LoginModal},
  computed:{
    ...mapGetters({
      getLoginModal: 'getLoginModal'
    }),
    hideNavigation(){
      process.env.NODE_ENV === "development" ? console.log( 'this.$vuetify.breakpoint.xsOnly', this.$vuetify.breakpoint.xsOnly ) : null;
      return this.$route.name == 'Login' || (this.$route.name === 'Donut' && this.$vuetify.breakpoint.xsOnly)
    }
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
  html, body{
    height: 100%
  }
  #app{
    height: 100vh;
    width: 100%;
    &.hide-nav .v-application--wrap{
      grid-template-rows:1px auto;
      .v-main{
        grid-area: content
      }
    }
    .v-application--wrap{
      display: grid;
      margin-left: auto;
      margin-right: auto;
      grid-template-columns: auto;
      grid-template-rows:56px auto;
      grid-template-areas: "nav"
      "content";
    }
    &.desktop {
      .v-overlay.v-overlay--active{
        z-index:9000!important;
      }
      &.hide-nav .v-application--wrap{
        grid-template-columns:1px auto;
      }
      .v-application--wrap{
        grid-template-columns:minmax(200px, 256px) 1fr;
        grid-template-rows: auto;
        grid-template-areas: "nav content";
        .navigation{
          grid-area: nav;
          overflow-x: visible;
          position: fixed;
          &.v-navigation-drawer--is-mouseover{
            z-index: 9999999;
            position: fixed;
          }
        }
        .v-main{
          grid-area:content;
        }
      }
    }

    &.tablet {
      .v-overlay.v-overlay--active{
        z-index:9000!important;
      }
      &.hide-nav .v-application--wrap{
        grid-template-columns:1px auto;
      }
      .v-application--wrap{
        grid-template-columns:56px 1fr!important;
        grid-template-rows:auto!important;
        grid-template-areas:
        "nav content";
      }
    }
    .v-dialog__content{
      z-index: 10002!important;
    }
    .v-dialog{
      z-index: 10000!important;
    }
    .v-overlay.v-overlay--active{
      // z-index:9000!important;
    }

  }
  .elevation-prime, .v-sheet.v-card:not(.v-sheet--outlined).elevation-prime{
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 9%), 0px 1px 18px 0px rgb(0 0 0 / 8%)
  }

  .tabs2{

    border: 1px solid #ddd!important;
    // border: 1px solid var(--v-pinkBG-lighten1)!important;
    padding: 5px;
    background-color: #f6f6f6!important;
    // background-color: var(--v-pinkBG-lighten2);
    border-radius: 32px;
    box-shadow: inset 0 2px 4px rgba(0,0,0,.05);

    button{
      margin: 0;
      border: none;
      span{
        color: #888;
      }
    }
    .theme--light.v-btn.v-btn--has-bg{
      // background: #FEF6F0;
      background: transparent;
    }
    .theme--light.v-btn:focus::before {
    opacity: 0.0!important;
    }
    .theme--light.v-btn:hover::before {
    opacity: 0.00!important;
    }
    .v-item--active{
      border: none!important;
      background-color: #fff!important;
      box-shadow: 0 2px 4px rgba(0,0,0,.2);
      span{
        color: var(--v-primary-base);
      }
    }
  }
</style>

<template>
  <div class="nav-wrapper">

    <div class="mobile-nav d-flex align-center justify-space-between" v-if="$vuetify.breakpoint.xsOnly">
      <div class="d-flex flex-grow-0 align-center" @click.stop="drawer = !drawer">
        <v-icon>mdi-menu-left</v-icon>
        <img class="logo mr-2" src="@/assets/logo/Donut Avatar - Stroke.svg" alt="">
        <h2 class="bubble-font pinkBG--text text--darken-3">TokenDonuts</h2>
      </div>
    </div>

    <v-navigation-drawer
        v-model="drawer" class="navigation" fixed
        :permanent="$vuetify.breakpoint.smAndUp"
        :expand-on-hover="$vuetify.breakpoint.smOnly"
      >
      <div class="">
        <div class="logo-wrapper d-flex align-center py-2 pt-1 pb-0">
          <img class="logo ml-1 mr-2" src="@/assets/logo/Donut Avatar - Stroke.svg" alt="">
          <div class="logo-title">
            <span class="bubble-font primary--text text--darken-2 text-truncate">Token Donuts</span>
            <div class="tagline text-truncate text-center">Tasty slices of crypto projects.</div>
          </div>
        </div>

        <v-divider color="pink"></v-divider>

        <v-btn class="new-done flex-grow-1 full bubble-font my-4 px-0 mx-2" to="/create" large>
          🍩 <span>Make Donut</span>
        </v-btn>

        <v-divider color="pink"></v-divider>
      </div>

      <!-- Nav links  -->
      <v-list nav dense>
        <v-list-item link :to="`/${link.path}`" active-class="primary"
          v-for="(link, lin) in links" :key="lin">
          <v-list-item-icon>
            <v-icon>{{link.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{link.text}}</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>

      <!-- My Box -->
      <div class="" v-if="user.id">
        <!-- <div class="my-box">
          <v-btn color="primary" dark :to="{ name: 'Box', params: {} }" class="wrapper d-flex align-center justify-center flex-column ">
            <img class="icon-big" src="@/assets/icons/My-Box.svg" alt="">
            <img class="icon-small" src="@/assets/icons/Box Small.svg" alt="">
            <h3 class="bubble-font mt-2 text">My Box</h3>
          </v-btn>
        </div> -->
        <v-btn class="primary my-box flex-grow-1 full bubble-font my-4 px-0 mx-2" to="/box" large>
          <img class="icon-small" src="@/assets/icons/My-Box-Small.svg" alt=""> <span class="ml-1">My Box</span>
        </v-btn>
        <v-divider color="pink" class="mt-1"></v-divider>
      </div>




      <div class="pa-2 mb-6">
        <template v-if="$isEmpty(user.id)">
          <!-- user is not logged in -->
          <v-btn class=" account d-flex align-center justify-start col-12" :to="{name: 'Login'}" color="white">
            <v-avatar><v-icon>mdi-account-circle</v-icon></v-avatar>
            <div class="text-truncate">Login</div>
          </v-btn>
        </template>
        <template v-else>
          <v-list nav dense class="pa-0 mb-2">
            <v-list-item link @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
          <!-- user is logged in -->
          <v-btn :to="{name: 'Profile'}" class="account d-flex align-center" text color="primary darken-3">
            <v-avatar class=""><v-icon>mdi-account-circle</v-icon></v-avatar>
            <div class="text-truncate profile-text ml-5">My Profile</div>
          </v-btn>
        </template>
      </div>

      </v-navigation-drawer>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Navigation',
  props:['page'],
  computed:{
    ...mapGetters({
      user: 'getCurrentUser'
    }),
  },
  data:() => ({
    drawer: false,
    links:[
      {text:'Donuts', icon:'mdi-chart-arc', path:""},
      {text:'Calendar', icon:'mdi-calendar-month', path:"calendar"},
      {text:'Roadmap', icon:'mdi-chef-hat', path:"roadmap"},
      {text:'Feedback', icon:'mdi-message-text', path:"feedback"}
    ],
  }),
  methods:{
    logout(){
      this.$store.dispatch('logout');
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.nav-wrapper{
  width: 100%;
  background: pink;
  background: #FFAFBD;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #ffc3a0, #FFAFBD);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #ffc3a0, #FFAFBD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .mobile-nav{
    height: 100%;
    .logo{
      width: 44px;
    }
  }

  .navigation{
    height: 100%;
    // background: pink!important;
    background: #FFAFBD;  /* fallback for old browsers */
    background: -webkit-linear-gradient(-45deg, #ffc3a0, #FFAFBD);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(-45deg, #ffc3a0, #FFAFBD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    z-index: 8500;
    width: 100%;
    .v-navigation-drawer__content{
      display:flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-between;
    }

    .mobile-bg-heading{
      grid-area: content;
      height: 40vh;
      z-index: 1;
      background: pink;
      border-bottom: 2px solid #fff;
    }
    .new-done{
      width: 92%;
    }
    .my-box{
      width: 92%;
    }

    &.v-navigation-drawer--mini-variant{
      .new-done{
        width: 40px;
        min-width: auto!important;
        span span{
          display: none;
        }
      }
      .account{
        padding: 0;
        width: auto;
        min-width: auto!important;
        .profile-text{
          display: none;
        }
      }
      .my-box{
        width: 40px;
        min-width: auto!important;
        span span{
          display: none;
        }
      }
      // .my-box{
      //   max-width: 90%;
      //   margin: 0 auto;
      //   .text{
      //     display: none;
      //   }
      //   .icon-big{display: none;}
      //   .icon-small{display: block; max-width: 32px; padding: 6px 0;}
      // }
    }
    &.v-navigation-drawer--is-mouseover{
      .new-done{
        width: 92%!important;
        max-width: auto;
      }
      .my-box{
        width: 92%!important;
        max-width: auto;
      }
    }

    .logo-wrapper{
      height: 77px;
      .logo{
        width: 48px;
      }
      .logo-title{
        font-size: 1.7rem!important;
      }
      .tagline{
        font-size: 13px;
        margin-top: -5px;
      }
    }

    .v-list{
      .v-list-item__title{
        font-size: 1.1rem!important;
        line-height: 1.1rem;
        color: var(--v-primary-darken4);
      }
      .v-list-item__icon i{color: var(--v-primary-darken4)}

      .v-list-item--active{
        .v-list-item__title{
          color: white;
        }
        .v-list-item__icon i{ color: white}
      }
    }

    .active{
      // background: #f1dfe0;
    }

    .my-box{
      .icon-small{display: block; max-width: 32px;}

      .wrapper{
        background-color: rgba(0, 0, 0, .1);
        border: 3px dashed var(--v-pinkBG-darken2);
        border-radius: 4px;
        &:hover{
          background-color: rgba(255, 255, 255, .5);
          cursor: pointer;
        }
      }
      .icon{width: 55%}
    }
  }
  .account{
    padding: 0!important;
    .v-avatar{max-width: 40px!important; min-width: 40px!important}
    .v-btn__content{
      justify-content: start;
    }
    div{
      max-width: 75%;
    }
  }
}
</style>

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqkWPFm-HqekVpSTDY_ucYKJ3R7IGVEVk",
  authDomain: "token-donuts.firebaseapp.com",
  projectId: "token-donuts",
  storageBucket: "token-donuts.appspot.com",
  messagingSenderId: "353964259846",
  appId: "1:353964259846:web:3d33e32e8642a23112a187",
  measurementId: "G-NL33WNDKSM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
export const analytics = getAnalytics();
export const cloud = getFunctions();
export const storage = getStorage();

// export const firestorage = firebase.storage();
// export const cloud = firebase.functions();
// export const analytics = firebase.analytics();

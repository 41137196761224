import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Plugins from './plugins/plugins'
import Dayjs from './plugins/Day';
import ImageConversionPlug from './plugins/imageConversion'

Vue.use(Plugins)
Vue.use(ImageConversionPlug)
Vue.use(Dayjs)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


import Vue from 'vue'
import VueRouter from 'vue-router'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/firebase.js"
import store from '@/store/'

Vue.use(VueRouter)


function loadView(src, view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/${src}/${view}.vue`)
}
// eslint-disable-next-line no-unused-vars
function $isUndefined(thing) {
  if (thing === "undefined" || thing === null){
    return true;
  }
  else{
    return false;
  }
}
// eslint-disable-next-line no-unused-vars
function $isEmpty(obj) {
  for(var key in obj) {
    if(Object.prototype.hasOwnProperty.call(obj, key))
    return false;
  }
  return true;
}

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: loadView('views', 'NotFound')
  },
  {
    path: '/',
    name: 'Home',
    component: loadView('views', 'Home')
  },
  {
    path: '/roadmap',
    name: 'Roadmap',
    component: loadView('views', 'Roadmap')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: loadView('views', 'Calendar')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: loadView('views', 'Feedback')
  },
  {
    path: '/box',
    name: 'Box',
    component: loadView('views', 'Box'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: loadView('views', 'Profile'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/donut/:donutId',
    name: 'Donut',
    component: loadView('views', 'Donut')
  },
  {
    path: '/emailAuth',
    name: 'emailAuth',
    component: loadView('views','emailAuth'),
  },
  {
  path: '/login',
  name: 'Login',
  component: loadView('views','Login'),
  meta: {
        disableIfLoggedIn: true
    }
},
{
  path: '/sign-up',
  name: 'SignUp',
  component: loadView('views','SignUp')
},
{
  path: '/create',
  name: 'Create',
  component: loadView('views', 'Create'),
  meta:{
    requiresAuth: true
  }
},
{
  path: '/edit/:donutId',
  name: 'Edit',
  component: loadView('views', 'Create'),
  meta:{
    requiresAuth: true
  }
}
]


const router = new VueRouter({
  mode: 'history',
  routes
})


async function getUserMeta(uid){
  let userMeta = await store.dispatch('getUserMeta', uid).then((userData)=>{
    return userData
  }).catch((err)=>{
    console.log('error', err);
  });
  return userMeta;
}

//// Before Each route runs
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const disableIfLoggedIn = to.matched.some(record => record.meta.disableIfLoggedIn)
  // const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)

  await onAuthStateChanged(auth, (user) => {
    // process.env.NODE_ENV === "development" ? console.log( 'check user', $isUndefined(user), $isEmpty(user), user ) : null;
    if(!$isEmpty(user)){
      const meta = {'id': user.uid, 'data': user.email};
      store.dispatch('saveUser', meta);
      if(store.getters.hasUserMeta){
        beforeRouterLogic(userMeta)
      }
      else{
        getUserMeta(user.uid).then(userMeta => {
          // process.env.NODE_ENV === "development" ? console.log( 'userMeta', userMeta ) : null;
          beforeRouterLogic(userMeta)
        })
      }
    }
    else{
      beforeRouterLogic(false)
    }
  });

  function beforeRouterLogic(isAuthed){
      process.env.NODE_ENV === "development" ? console.log( 'user is logged in?', isAuthed ) : null;
      if (requiresAuth && !isAuthed) return next('/login')
      else{
        if(isAuthed.showProfileFirst){
          to.path === "/profile" ? next() : next('/profile');
        }
        else return next()
      }
      // else if (requiresUnauth && isAuth) next('/')
  }


})

export default router

export default {
install(Vue) {

  // this is to help firebase data object convert to a usable array
  Vue.prototype.$objectToArray = function (originalArr, filterId, filterVal) {

      // process.env.NODE_ENV === "development" ? console.log('preflight $objectToArray', typeof originalArr, originalArr, filterId, filterVal) : null
      const loopArr = Object.keys(originalArr).map(async (arrItem) => {
        return originalArr[arrItem]
      });

      return Promise.all(loopArr).then(results => {
        // process.env.NODE_ENV === "development" ? console.log('results of loop', results) : null
        if(filterId != null || filterId != undefined){
          const filtered_results = results.filter(item => {
            return Object.entries(item).some(k => k[0].includes(filterId) && k[1] == filterVal)
          });
          return filtered_results
        }
        else{
          return results
        }

      });
  };


  // this is to help firebase data object convert to a usable array
  Vue.prototype.$isEmpty = function (obj) {
    for(var key in obj) {
      if(Object.prototype.hasOwnProperty.call(obj, key))
      return false;
    }
    return true;
  };

  Vue.prototype.$isEmptyNested = function (obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  };

  // this is to help firebase data object convert to a usable array
Vue.prototype.$isUndefined = function (thing) {
  if (thing == "undefined" || thing == null){
    return true;
  }
  else{
    return false;
  }
};

Vue.prototype.$idealTextColor = function(bgColor) {
    function getRGBComponents(color) {
      var r = color.red;
      var g = color.green;
      var b = color.blue;
      return {
        R: parseInt(r, 16),
        G: parseInt(g, 16),
        B: parseInt(b, 16)
      };
    }
    function hexToRGB(hex){
      if (hex.charAt(0) === '#') {
          hex = hex.substring(1);
      }

      if (hex.length !== 3 && hex.length !== 6) {
          return '#000000';
      }

      if (hex.length === 3) {
          hex = hex.split('').map(c => c.repeat(2)).join('');
      }

      let red = parseInt(hex.substring(0, 2), 16);
      let blue = parseInt(hex.substring(4, 6), 16);
      let green = parseInt(hex.substring(2, 4), 16);

      return { red, green, blue };
    }

    if(!this.$isUndefined(bgColor)){
      var nThreshold = 105;
      var bgColorHex = hexToRGB(bgColor);
      var components = getRGBComponents(bgColorHex);
      var bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114);

      return ((255 - bgDelta) < nThreshold) ? "#000000" : "#ffffff";
    }
    else{return '#000000'}

}


Vue.prototype.$lightenDarkenColor = function (color, amount) {
	var usePound = false;
	if (color[0] == "#") {
		color = color.slice(1);
		usePound = true;
	}
	var num = parseInt(color, 16);
	var r = (num >> 16) + amount;
	if (r > 255) {
		r = 255;
	} else if (r < 0) {
		r = 0;
	}
	var b = ((num >> 8) & 0x00FF) + amount;
	if (b > 255) {
		b = 255;
	} else if (b < 0) {
		b = 0;
	}
	var g = (num & 0x0000FF) + amount;
	if (g > 255) {
		g = 255;
	} else if (g < 0) {
		g = 0;
	}
	return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}




/*
 * Where to use: Use in vue templates to determine deeply nested undefined/null values
 * How to use: Instead of writing parent?.child?.child2 you can write
 *            isAvailable(parent, 'child.child2')
 * @author    Smit Patel
 * @params    {Object} parent
 *            {String} child
 * @return    {Boolean}     True if all the nested properties exist
 */
Vue.prototype.$isAvailable = function(parent, child) {
  try {
    const childArray = String(child).split('.');
    let evaluted = parent;
    childArray.forEach((x) => {
      evaluted = evaluted[x];
    });
    return !!evaluted;
  } catch {
    return false;
  }
}




  // Vue.prototype.$logEvent = function (action, bus_obj, icon, color, details, user, lockId){
  //   return new Promise((resolve, reject) => {
  //   let data = {
  //     action: action,
  //     bus_obj: bus_obj,
  //     icon: icon,
  //     color: color,
  //     details: details,
  //     cId: user.cId,
  //     created_by: user
  //     }
  //     if(lockId){
  //       data.lockId = lockId;
  //     }
  //     // commit code
  //     store.dispatch('auditData/insert', data)
  //     .then(() => {
  //       resolve('successfully logged action');
  //     }).catch((err) => {
  //       reject(`error creating action: ${err}`);
  //     })
  //   });
  // };





  // // 1. add global method or property
  // Vue.myGlobalMethod = function () {
  //   // some logic ...
  // }

  // 2. add a global asset
  // Vue.directive('my-directive', {
  //   bind (el, binding, vnode, oldVnode) {
  //     // some logic ...
  //   }
  //   ...
  // })

  // 3. inject some component options
  // Vue.mixin({
  //   created: function () {
  //     // some logic ...
  //   }
  //   ...
  // })


  // Vue.mixin({
  //   data: function () {
  //     return {
  //       // some logic ...
  //
  //     }
  //   }
  // })



  // // 4. add an instance method
  // Vue.prototype.$myMethod = function (methodOptions) {
  //   // some logic ...
  // }
  }
}
